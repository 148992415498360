import { Card, CardContent, Grid } from "@mui/material";
import { minWidth } from "@mui/system";
import { HeadFC, Link } from "gatsby";
import * as React from "react";
import { MainFrame } from "../components/MainFrame";

const NotFoundPage = () => {
  const content = (
    <Card sx={{ minWidth: "80vw" }}>
      <CardContent>
        <h3>お探しのページが見つかりません。</h3>
        <div>
          <Link to="/">TOPに戻る</Link>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <MainFrame type="website">
      <Grid container>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          {content}
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </MainFrame>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
